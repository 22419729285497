@layer components {

    /**
        Værsågod custom Tailwind components
        https://tailwindcss.com/docs/adding-custom-styles#adding-component-classes
     */

    .heading-xs {
        @apply text-20 leading-1;
    }

    .heading-s {
        @apply text-32 leading-1_1;
    }

    .heading-m {
        @apply text-40 leading-1_25;
    }

    .heading-mm { // medium mobile
        @apply text-24 leading-1_1;
    }

    .heading-l {
        @apply text-48 leading-1;
    }

    .heading-xl {
        @apply text-56 leading-1;
    }

    .heading-xxl {
        @apply text-62 leading-1;
    }

    .text-tiny {
        @apply text-12 leading-1_5;
    }

    .text-small {
        @apply text-14 leading-1_5;
    }

    .text-regular {
        @apply text-16 leading-1_5;
    }

    .text-medium {
        @apply text-18 leading-1_5;
    }

    .text-large {
        @apply text-20 leading-1_5;
    }

    .pad-top {
        @apply pt-100 mp:pt-160 lp:pt-180;
    }

}
