@layer utilities {

    /**

        Værsågod custom Tailwind utilities
        https://tailwindcss.com/docs/adding-custom-styles#adding-custom-utilities

        These utilities are provided via tailwind.config.js:

        .gpu
        .anti
        .round
        .full
        .center
        .center-x
        .center-y
        .clear
        .scrollable
        .scrollable-x
        .scrollbar-hidden

        .wrapper (container with outer margins and max content width)
        .outer-margins (container with outer margins, but no max-width)
        .gutters (gap-based gutters, both horizontal and vertical)
        .gutters-x (gap-based horizontal gutters)
        .gutters-y (gap-based vertical gutters)
        .pad-wrapper (wrapper meant to be used with pad-gutters children, i.e. like the old .g-wrap)
        .pad-gutters (padding-based gutters, both horizontal and vertical)
        .pad-gutters-x (padding-based horizontal gutters)
        .pad-gutters-y (padding-based vertical gutters)

     */
    .fullheight {
        height: 100vh;
        @supports (--custom:property) {
            height: calc(var(--vh, 1vh) * 100);
        }
    }

    .min-fullheight {
        min-height: 100vh;
        @supports (--custom:property) {
            min-height: calc((var(--vh, 1vh) * 100) - var(--alert, 0px));
        }
    }

    .transition-underline {
        text-decoration: underline;
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                text-decoration: none;
            }
        }
        @supports (text-decoration-color: transparent) {
            text-decoration-color: currentColor;
            transition: text-decoration-color 0.3s;
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    text-decoration: underline;
                    text-decoration-color: transparent;
                }
            }
        }
    }

    .transition-no-underline {
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                text-decoration: underline;
            }
        }
        @supports (text-decoration-color: transparent) {
            text-decoration: underline;
            text-decoration-color: transparent;
            transition: text-decoration-color 0.3s;
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    text-decoration-color: currentColor;
                }
            }
        }
    }

    /**
        Vignettes
    */
    .vignette-hero-top::before,
    .vignette-hero-full::before,
    .vignette-card::before,
    .vignette-flexi::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        mix-blend-mode: multiply;
        //backdrop-filter: blur(10px);
    }

    .vignette-hero-top::before {
        background: linear-gradient(184.78deg,rgba(22,52,96,.462) 10%,rgba(22,52,96,0) 15%);
    }

    .vignette-hero-full::before {
        background: linear-gradient(184.78deg,rgba(22,52,96,.462) 10%,rgba(22,52,96,0) 15%),linear-gradient(195.71deg,rgba(3,3,3,0) 18.82%,rgba(1,1,1,0) 50%,rgba(0,0,0,.59) 66.89%);
    }

    .vignette-card::before {
        height: 50%;
        top: auto;
        bottom: 0;
        background: linear-gradient(166.28deg, rgba(3, 3, 3, 0) 26.63%, rgba(3, 3, 3, 0.67) 69.29%);
    }

    .vignette-flexi::before {
        background: linear-gradient(0deg, rgba(0, 0, 0, var(--vignette-from, 0.61)), rgba(0, 0, 0, var(--vignette-to, 0.61)));
    }

    .press {
        transition: color 0.3s, background-color 0.3s, border-color 0.3s, transform 0.1s linear;
        transform: scale(1);
        &:active {
            transform: scale(0.95);
        }
    }

    .ellipsis {
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        max-width: 100%;
        white-space: nowrap;
        vertical-align: bottom;
    }

}
