.textimage {

    // Text left, image right (default)
    &--text-left &__image,
    &--auto:nth-child(even) &__image {
        @apply m:col-span-6 m:col-start-7;
    }

    &--text-left &__text,
    &--auto:nth-child(even) &__text {
        @apply m:col-span-6 m:col-start-1;
    }

    &--text-left.has-circle &__text,
    &--auto:nth-child(even).has-circle &__text {
        @apply m:col-span-5;
    }

    &--text-left &__circle,
    &--auto:nth-child(even) &__circle {
        @screen m {
            left: calc(50% - 30px);
        }
        @screen l {
            left: calc(50% - 60px);
        }
    }

    &--text-left.has-circle &__image::before,
    &--auto:nth-child(even).has-circle &__image::before {
        left: 100%;
    }

    // Image left, text right
    &--image-left &__image,
    &--auto:nth-child(odd) &__image {
        @apply m:col-span-6 m:col-start-1;
    }

    &--image-left &__text,
    &--auto:nth-child(odd) &__text {
        @apply m:col-span-6 m:pl-20 l:pr-40;
    }

    &--image-left.has-circle &__text,
    &--auto:nth-child(odd).has-circle &__text {
        @apply m:col-span-5 m:col-start-8;
    }

    &--image-left &__circle,
    &--auto:nth-child(odd) &__circle {
        transform: scaleX(-1);
        @screen m {
            right: calc(50% - 30px);
        }
        @screen l {
            right: calc(50% - 60px);
        }
    }

    // Some extra background color for when the block has a circle background
    @screen m {
        &.has-circle &__image::before {
            content: "";
            display: block;
            width: 50vw;
            height: 100vw;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            @apply bg-theme-accent;
        }
        &.has-circle.theme-beige &__image::before {
            @apply bg-beige-dark;
        }
        &--image-left.has-circle &__image::before,
        &--auto:nth-child(odd).has-circle &__image::before {
            right: 100%;
        }
    }

}
