.lazyload,
.lazyloading {
    opacity: 0;
}

.lazyloaded {
    @apply transition-opacity duration-300 ease-in-sine;
}

.lazyload.lazyzoom, .lazyloading.lazyzoom {
    transform: scale(1.02, 1.02) translate(0, 1vh);
    transform-origin: center bottom;
}

.lazyloaded.lazyzoom {
    transform: scale(1, 1) translate(0, 0);
    transform-origin: center bottom;
    transition: opacity 0.5s theme('transitionTimingFunction.in-sine'), transform 1s theme('transitionTimingFunction.out-quad');
}

.no-js img.lazyload[data-src],
.no-js img.lazyload[data-srcset] {
    display: none;
}

svg {
    display: block;
}

// Prevent grid blowout on tiny screens
*:not([data-gridoverlay]) > .grid:not(.blowout) {
// Max-width = (outerMargins * 2) + (gutter width * number of columns)
    @media (max-width: calc((theme('gutterMap.default') * theme('gridColumns')) + (theme('outerMarginsMap.default') * 2))) {
        grid-template-columns: minmax(0, 1fr);
    }
}

video[playsinline] {
    pointer-events: none;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

button, a {
    touch-action: manipulation;
    border-radius: 0;

    &[disabled],
    &[aria-disabled] {
        cursor: default;
    }
}

button {
    appearance: none;
    //font-weight: normal;
    text-align: left;
}

[hidden] {
    display: none !important;
}

input:required {
    box-shadow: none;
}

html.outline,
html.no-outline * {
    outline: none !important;
}

select {
    appearance: none;
}

html.reduce-motion {
    *, *:before, *:after {
        animation: none !important;
        transition: none !important;
    }
}

::-moz-selection {
//background: theme('colors.purple.light'); //color: theme('colors.black.DEFAULT');
}

::selection {
//background: rgb(210, 175, 249, 0.99); // Same as "purple light" //color: theme('colors.black.DEFAULT');
}

input[class*="appearance-none"] {
    -webkit-appearance: none;
}

table {
    th, td {
        text-align: left;
        padding: 0;
        font-weight: normal;
        vertical-align: top;
    }
}

//img {
//    pointer-events: none;
//}

strong, b {
    font-weight: normal;
}

i, em {
    font-style: italic;
}

html.js:not(.js-error):not(.is-preview) [data-reveal] {
    opacity: 0;
}

.hit::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    min-width: 44px;
    min-height: 44px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

svg.icon * {
    fill: inherit !important;
}

img, video, svg {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

/* Hide Calendar Icon In Chrome */
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.hover-scale {
    @media (hover: hover) and (pointer: fine) {
        transition: transform 0.5s;
        a:hover & {
            transition: transform 1s theme('transitionTimingFunction.out-quint');
            transform: scale(1.03, 1.03);
        }
    }
}

.animated-link {
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0% 0.125em;

    transition: background-size 0.3s;

    @media (hover: hover) and (pointer: fine) {
        .group:hover &,
        &:hover {
            background-size: 100% 0.125em;
        }
    }
}

input.floating-label,
textarea.floating-label {
    & + label > span {
        transform-origin: left top;
    }
    &:not(:placeholder-shown),
    &:focus,
    &.has-value {
        & + label > span {
            transform: translate(0, -12px) scale(0.63636364, 0.63636364);
        }
        & {
            padding-top: 20px;
            padding-bottom: 6px;
        }
    }
}

select.floating-label {
    & + label > span {
        transform-origin: left top;
    }
    &.has-value {
        & + label > span {
            transform: translate(0, -12px) scale(0.63636364, 0.63636364);
        }
        & {
            padding-top: 20px;
            padding-bottom: 6px;
        }
    }
}

.styled-scrollbar {

    /* Firefox */
    & {
        scrollbar-width: thin;
        scrollbar-color: var(--thumb, white) var(--track, black);
    }

    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: var(--track, black);
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--thumb, white);
        border-radius: 14px;
        border: 3px solid var(--track, black);
    }

}

.wrapper.slim {
    max-width: 1440px;
}

/**
    Content blocks + hero
 */
.content-block {

    @apply my-40 m:my-60 l:my-80 xl:my-100 py-60 l:py-80 xl:py-100;

    &:last-child {
        margin-bottom: 0;
    }

    & + & {
        @apply -mt-40 m:-mt-60 l:-mt-80 xl:-mt-100;
    }

    &.theme-beige + &.theme-beige,
    &.theme-beige-dark + &.theme-beige-dark,
    &.theme-green-dark + &.theme-green-dark,
    &.theme-white + &.theme-white {
        @apply -mt-100 m:-mt-120 l:-mt-160 xl:-mt-200;
    }

    &.images.theme-beige + &.images.theme-beige {
        @apply -mt-140 m:-mt-150 mp:-mt-160 l:-mt-200 xl:-mt-260;
    }

    .hero + &.theme-beige,
    .hero.bleed + & {
        margin-top: 0;
    }

}

.hero:not(.bleed) + #content.content-block {
    margin-top: 0;//-mt-40 mp:-mt-60;
}

.hero-slides.theme-beige + #content.content-block.theme-beige,
.hero-slides.theme-beige-dark + #content.content-block.theme-beige-dark,
.hero-slides.theme-white + #content.content-block.theme-white,
.hero-slides.theme-green-dark + #content.content-block.theme-green-dark {
    padding-top: 0;
}

/**
    Themes
 */
.theme-beige {
    --theme-bg: theme('colors.beige.DEFAULT');
    --theme-bg-alt: theme('colors.beige.dark');
    --theme-bg-ui: theme('colors.green.dark');
    --theme-text: theme('colors.black.DEFAULT');
    --theme-text-ui: theme('colors.white');
    --theme-accent: theme('colors.green.dark');
}

.theme-beige-dark {
    --theme-bg: theme('colors.beige.dark');
    --theme-bg-alt: theme('colors.beige.DEFAULT');
    --theme-bg-ui: theme('colors.green.dark');
    --theme-text: theme('colors.black.DEFAULT');
    --theme-text-ui: theme('colors.white');
    --theme-accent: theme('colors.green.dark');
}

.theme-green-dark {
    --theme-bg: theme('colors.green.dark');
    --theme-bg-alt: theme('colors.green.DEFAULT');
    --theme-bg-ui: theme('colors.white');
    --theme-text: theme('colors.white');
    --theme-text-ui: theme('colors.green.dark');
    --theme-accent: theme('colors.beige.DEFAULT');
}

.theme-white {
    --theme-bg: theme('colors.white');
    --theme-bg-alt: theme('colors.grey.DEFAULT');
    --theme-bg-ui: theme('colors.green.dark');
    --theme-text: theme('colors.black.DEFAULT');
    --theme-text-ui: theme('colors.white');
    --theme-accent: theme('colors.green.dark');
}
