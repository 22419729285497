/** Tailwind **/
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/** Fonts, includes, Værsågod reset **/
//@import './base/fonts.pcss';
@import './base/reset.pcss';

/** Custom Tailwind components and utilities */
@import './tailwind/components.pcss';
@import './tailwind/utilities.pcss';

/** Misc styles **/
@import './styles/html.pcss';
@import './styles/wysiwyg.pcss';
@import './styles/flickity.pcss';

/** BEM **/
@import './bem/booking-widget.pcss';
@import './bem/textimage.pcss';
