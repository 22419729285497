.wysiwyg {

    p, ul, ol {
        margin-bottom: 1.5em;
    }

    strong, b {
        @apply font-semibold;
    }

    em, i {
        font-style: italic;
    }

    a {
        @apply text-theme-accent font-semibold transition-underline;
    }

    h1, h2 {
        @apply heading-s mp:heading-m l:heading-l text-theme-accent font-bold;
        margin: 1em 0 0.5em 0;
        line-height: 1.15;
    }

    h3 {
        @apply heading-xs mp:heading-s l:heading-m text-theme-accent font-bold;
        margin: 1em 0 0.5em 0;
    }

    h4, h5, h6 {
        @apply mp:heading-xs l:heading-s text-theme-accent font-semibold;
        margin: 1.5em 0 0.5em;
    }

    ul {
        list-style: disc outside;
        padding-left: 1em;
    }

    ol {
        list-style: decimal outside;
        padding-left: 1em;
    }

    li {
        display: list-item;
        padding-left: 0.5em;
    }

    ul li {
        list-style-type: disc;
    }

    p, h1, h2, h3, h4, h5, h6, ul, ol {
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

}
